<template>
  <div>
    <keep-alive>
      <m-gmaps ref="map" class="dashboard-discovery-map" :markers="markers" :marker-details="markerDetails" />
    </keep-alive>
    <q-btn
      icon="far fa-location"
      class="locationChangeBtn q-px-xs"
      :label="$t('change_map_location')"
      color="white"
      text-color="primary"
      no-caps
      dense
      rounded
    >
      <q-menu :offset="[0, 8]" class="transparent">
        <m-select-query
          v-model="newLocationQuery"
          :placeholder="$t('search_location')"
          icon="fas fa-location"
          color="primary-tint"
          bg-color="white"
          class="q-ma-xs"
          dense
          :change-location="true"
          :query="locationAutocomplete"
        />
      </q-menu>
    </q-btn>
    <q-btn
      fab
      color="white"
      icon="fas fa-plus"
      class="zoomInBtn"
      @click="() => setZoom(+1)"
    />
    <q-btn
      fab
      color="white"
      icon="fas fa-minus"
      class="zoomOutBtn"
      @click="() => setZoom(-1)"
    />
    <q-btn
      icon="far fa-location"
      class="recenterBtn"
      color="white"
      text-color="primary"
      fab
      @click="setMapToGeolocation"
    />
    <m-mobility-nearby-cards v-if="hasMobilityCards && (nearbyItems || loading)" />
    <m-mobility-nearby-list v-else-if="nearbyItems || loading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { query as placeQuery, details as placeDetails } from 'api/places'
import authentication from 'mixins/authentication'

export default {
  name: 'Dashboard',
  mixins: [authentication],
  data () {
    return {
      markers: [],
      markerDetails: {}
    }
  },
  computed: {
    ...mapGetters({
      geoip: 'geoip',
      latLng: 'geolocate/latLng',
      nearbyItems: 'discovery/items',
      loading: 'discovery/loading',
      mobility: 'partner/mobility',
      bookables: 'partner/bookables'
    }),
    newLocationQuery: {
      get () {
        return null
      },
      set (val) {
        placeDetails(val.value).then(res => {
          this.setMapToGeolocation(res.data.geometry.location)
        })
      }
    },
    mobilityAndBookablesCombined () {
      return [...this.mobility, ...this.bookables]
    },
    hasMobilityCards () {
      return this.hasPermission('feature.mobility.nearby.cards')
    }
  },
  watch: {
    nearbyItems (newVal) {
      this.markers = []
      if (newVal && newVal.length) {
        let mobilityType = this.mobilityAndBookablesCombined.find(x => x.pref === newVal[0].type)
        this.markerDetails = { icon: mobilityType.icon, color: mobilityType.color.marker }
        newVal.forEach((item, index) => {
          const isBP = item.type === 'bp-b' || item.type === 'bp-c' || item.type === 'website'
          if (isBP) {
            this.setMapToGeolocation(({ lat: 51.497566, lng: 0.005855 }))
            this.markers = []
          } else {
            this.markers.push({
              key: `${item.name}-${index}`,
              position: { lat: item.latitude, lng: item.longitude },
              class: 'marker',
              icon: `${process.env.VUE_APP_API_URL}/icon/marker/${mobilityType.icon.name}.svg?width=24&height=36&color=${mobilityType.color.marker.replace('#', '%23')}`
            })
          }
        })
      }
    }
  },
  methods: {
    setMapToGeolocation ({ lat, lng }) {
      let useLat = this.latLng.latitude || this.geoip.latitude
      let useLng = this.latLng.longitude || this.geoip.longitude
      this.markers = []
      if (lat && lng) {
        useLat = lat
        useLng = lng
        this.markers.push({
          position: { lat: useLat, lng: useLng },
          icon: {
            url: require('assets/markers/map-marker@0.75x.png')
          },
          class: 'marker'
        })
      }
      this.$store.dispatch('map/setCenter', { lat: useLat, lng: useLng })
      this.$refs.map.$mapObject.panTo({
        lat: useLat,
        lng: useLng
      })
      this.$nextTick(() => {
        this.$refs.map.$mapObject.setZoom(15)
      })
    },
    setZoom (val) {
      const gMap = this.$refs.map.$mapObject
      const zoom = gMap.zoom
      gMap.setZoom(zoom + val)
    },
    locationAutocomplete (terms) {
      return placeQuery(terms)
        .then(response => {
          return response.data.map(result => {
            return {
              label: result.description,
              value: result.place_id,
              icon: 'place'
            }
          })
        })
    }
  }
}
</script>

<style lang="stylus">
  .dashboard-discovery-map
    width 100%
    height calc(100vh - 64px)
    z-index 1
  .locationChangeBtn
    position absolute
    z-index 10
    left 24px
    top 24px
    font-size .75em
  .recenterBtn
    position absolute
    z-index 10
    right 24px
    bottom 24px
  .zoomInBtn
    position absolute
    z-index 10
    left 24px
    bottom 96px
  .zoomOutBtn
    position absolute
    z-index 10
    left 24px
    bottom 24px

  @media only screen and (max-width: 936px)
    .recenterBtn
      bottom 324px
</style>
